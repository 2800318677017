import React, { useState, useEffect, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import Cookies from "js-cookie";
import { json } from "@codemirror/lang-json";
import { Button, Form } from "react-bootstrap";
import NavBar from "../NavBar/NavBar";
import { updateStatusDocuments } from "../../../API/MassUpdateDocs";

const MassUpdate = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [token, setToken] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setToken(Cookies.get("Token"));
  }, []); // Effect runs only once on mount

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  const onChange = useCallback((val) => {
    JSON.stringify(val);
    setJsonInput(val);
  }, []);
  
  const handleCurrentStatusChange = (event) => {
    setCurrentStatus(event.target.value);
  };

  const handleNewStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleSendRequest = async () => {
    try {
      setProcessing(true);
      const currentJsonInput = jsonInput; // Copy the value to a new variable
      const response = await updateStatusDocuments(
        currentStatus,
        newStatus,
        currentJsonInput,
        token
      );
      // Handle successful response, if needed
      console.log("Response:", response);
    } catch (error) {
      setErrorMessage(
        "Une erreur s'est produite lors de la mise à jour des documents."
      );
      console.error("Error updating documents:", error);
    } finally {
      setProcessing(false);
    }
  };
  return (
    <div>
      <NavBar />
      <Form.Group></Form.Group>

      <Form.Group
        style={{
          width: "400px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Form.Label>Current Status:</Form.Label>
        <Form.Control
          as='select'
          value={currentStatus}
          onChange={handleCurrentStatusChange}
        >
          {/* Ajoutez les options nécessaires pour les statuts actuels */}
          <option default>Aucun status choisis</option>
          <option value='reviewing'>reviewing</option>
          <option value='extracting'>extracting</option>
          <option value='completed'>completed</option>
          <option value='error'>error</option>
          <option value='ready_to_export'>ready_to_export</option>
          <option value='ready_to_review'>ready_to_review</option>
          <option value='uploaded'>uploaded</option>
          <option value='exported'>exported</option>
          <option value='reupload'>reupload</option>
          <option value='reexport'>reexport</option>

          {/* ... */}
        </Form.Control>
      </Form.Group>

      <Form.Group
        style={{
          width: "400px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Form.Label>New Status:</Form.Label>
        <Form.Control
          as='select'
          value={newStatus}
          onChange={handleNewStatusChange}
        >
          {/* Ajoutez les options nécessaires pour les nouveaux statuts */}
          <option default>Aucun status choisis</option>

          <option value='reviewing'>reviewing</option>
          <option value='extracting'>extracting</option>
          <option value='completed'>completed</option>
          <option value='error'>error</option>
          <option value='ready_to_export'>ready_to_export</option>
          <option value='ready_to_review'>ready_to_review</option>
          <option value='uploaded'>uploaded</option>
          <option value='exported'>exported</option>
          <option value='reupload'>reupload</option>
          <option value='reexport'>reexport</option>

          {/* ... */}
        </Form.Control>
      </Form.Group>

      <CodeMirror
        value={jsonInput}
        height='700px'
        theme={dracula}
        extensions={[json({ jsx: true })]}
        style={{
          width: "400px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onChange={onChange}
      />

      <Button
        variant='primary'
        className='mt-2'
        onClick={handleSendRequest}
        disabled={processing}
      >
        {processing ? "En traitement..." : "Envoyer la requête"}
      </Button>

      {errorMessage && (
        <div className='alert alert-danger mt-3' role='alert'>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default MassUpdate;
