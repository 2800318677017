/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { FiltersProvider } from "./Context/main";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./Language/I18n";
import { store, persistor } from './Store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <FiltersProvider>
            <App />
          </FiltersProvider>
        </BrowserRouter>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
