/** @format */

import React from "react";
import { Table } from "reactstrap"; 

const StatsTableDocs = ({ instances }) => {
    console.log(instances)
  const instancesArray = Array.isArray(instances) ? instances : [];

  let totalInstances = instancesArray.length || 0;
  let completedInstances =
    instancesArray.filter((instance) => instance.Status === "completed")
      .length || 0;
  let errorInstances =
    instancesArray.filter((instance) => instance.Status === "error").length ||
    0;

  let completionPercentage =
    totalInstances > 0
      ? ((completedInstances / totalInstances) * 100).toFixed(2)
      : 0;

  return (
    <>
      <Table striped style={{"width" : "450px"}}>
        <thead>
          <tr>
            <th>Total Docs</th>
            <th>Completed Docs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalInstances}</td>
            <td>{completedInstances}</td>
          </tr>
        </tbody>
      </Table>

      <Table striped style={{"width" : "450px"}}>
        <thead>
          <tr>
            <th>Error Docs</th>
            <th>Completion Docs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{errorInstances}</td>
            <td>{completionPercentage}%</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default StatsTableDocs;
