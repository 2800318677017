import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../Component/Admin';
import MassUpdate from '../Component/Admin/massUpdate/massUpdate';
import InstancesTableau from '../Component/Admin/DashBoard/InstancesTableau';
import Client from '../Component/Admin/DashBoard/Client';
import DocsTableau from '../Component/Admin/DashBoard/DocsTableau';
import DocsStatusTableau from '../Component/Admin/DashBoard/DocsStatusTableau';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export default function RouterAdmin() {
  const checkTokenExpiration = () => {
    const token = Cookies.get('Token');
    if (token) {
      const tokenExpirationTime = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      const currentTime = new Date();
      
      console.log("Token expiration time:", tokenExpirationTime);
      console.log("Current time:", currentTime);

      if (currentTime > tokenExpirationTime) {
        console.log("Token expired. Logging out...");
        Cookies.remove("Token");
        localStorage.clear();
        window.location.href = '/'; // Rediriger vers la page de déconnexion
      }
    }
  }; 

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 60000); // Vérifier toutes les 60 secondes (60000 ms)
  
    return () => {
      clearInterval(interval); // Nettoyer l'intervalle à la suppression du composant
    };
  }, []);

  return (
    <Routes>
      <Route path='/*' element={<Client />} />
      <Route path='/DashboardDocs' element={<DocsTableau />} />
      <Route path='/DocDashboard' element={<DocsStatusTableau />} />
      <Route path='/Dashboard' element={<InstancesTableau />} />
      <Route path='/massUpdate' element={<MassUpdate />} />  
      <Route path='/updateStatusDoc' element={<AdminDashboard />} />
    </Routes>
  );
}
