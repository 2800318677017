import React, { useState } from 'react';
import { updateStatusDocuments } from '../../../API/MassUpdateDocs'; // Assurez-vous de fournir le chemin correct vers votre fichier API
import Cookies from 'js-cookie';
const DocumentPopup = ({ document, onClose }) => {
  const [currentStatus, setCurrentStatus] = useState(document.Status);
  const [oldStatus, setOldStatus] = useState(document.OldStatus);
  const [newStatus, setNewStatus] = useState('');
  const idToken = Cookies.get("Token");
  const handleCurrentStatusChange = (e) => {
    setCurrentStatus(e.target.value);
  };

  const handleOldStatusChange = (e) => {
    setOldStatus(e.target.value);
  };

  const handleNewStatusChange = (e) => {
    setNewStatus(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = await updateStatusDocuments(currentStatus, newStatus, JSON.stringify([document.DocId]),idToken);
      console.log('Documents mis à jour:', updatedData);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour des documents:', error);
      // Ajouter ce console.log pour voir l'erreur détaillée
      console.log(error);
    }
  };
  
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={stopPropagation}>
        <div className="popup-content">
          <h2>Modifier le statut du document</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Statut actuel:
              <input type="text" value={currentStatus} onChange={handleCurrentStatusChange} />
            </label>
           {/* <label>
              Ancien statut:
              <input type="text" value={oldStatus} onChange={handleOldStatusChange} />
  </label>*/}
            <label>
              Nouveau statut:
              <input type="text" value={newStatus} onChange={handleNewStatusChange} />
            </label>
            <button type="submit">Enregistrer</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DocumentPopup;
