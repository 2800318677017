import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import Cookies from 'js-cookie';
import ModelSection from "./Client/ModelSection";
import ClientSection from "./Client/ClientSection";
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import DocSection from "./Client/DocSection";

export default function Client() {
  const navigate = useNavigate();
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const token = Cookies.get('Token');

  const handleRedirect = () => {
    if (selectedClient && selectedModel) {
      localStorage.setItem('selectedClient', selectedClient);
      localStorage.setItem('selectedModel', selectedModel);
      navigate('/Admin/Dashboard');
    } else {
      console.warn('Veuillez sélectionner un client et un modèle avant de rediriger.');
    }
  };

  return (
    <>
      <NavBar />
      <div className="container mt-5">
        <form>
          <h3>Choix par model</h3>
          <ModelSection token={token}  />
          
          <h3>Choix par client</h3>

          <ClientSection token={token}  />

          <h3>Choix par doc</h3>

          <DocSection token={token}  />
        </form>
      </div>
    </>
  );
}
