/** @format */
import CodeMirror from "@uiw/react-codemirror";
import ReactDOM from "react-dom";
import React, { useCallback, useEffect, useState } from "react";
import getDocs  from "../../../API/DocsADM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./popup.css";

//import StatusStats from "../Stats/StatsStatusDocs";
//import StatsTableDocs from "./statsTableaudocs";
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
  faSortUp,
  faSortDown,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CSVLink } from "react-csv";
import process from "process";
import DocumentPopup from "./popup";
//import { getPDFview } from "../../../../API/PDFView";
//import { getJdata } from "../../../../API/Jdata";
import * as configStatus from "../../../config/config.status";
import Cookies from "js-cookie";
import NavBar from "../NavBar/NavBar";
const DocsTableau = ({ instanceId, instanceName, onBack }) => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortByByName, setSortByByName] = useState(true);
  const [sortByById, setSortByById] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filterValue, setFilterValue] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const documentsPerPage = 10;
  const [sortByDateAscending, setSortByDateAscending] = useState(false);
  const [sortByNameAscending, setSortByNameAscending] = useState(true);
  const [sortByStatusAscending, setSortByStatusAscending] = useState(true);
  const idToken = Cookies.get("Token");
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const shouldEnableDWLDCSV =
    process.env.REACT_APP_DASHBOARD_DWLD_CSV === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const { t } = useTranslation();
  const client = localStorage.getItem("selectedClient");
  const instance = localStorage.getItem("selectedInstance");
  useEffect(() => {
    async function fetchData() {
      try {
        console.log( { instance , client})
        const documentList = await getDocs( client ,  idToken);

        // Formater la date pour chaque document
        const formattedDocumentList = documentList.map((doc) => ({
          ...doc,
          LastUpdate: formatDate(doc.LastUpdate),
        }));

        const uniqueStatuses = [
          ...new Set(formattedDocumentList.map((doc) => doc.Status)),
        ];

        setDocuments(formattedDocumentList);
        setUniqueStatuses(uniqueStatuses);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
        setIsLoading(false);
      }
    }

    fetchData();
  }, [instanceId, idToken]);

  const handleRefresh = async () => {
    setRefreshing(true); // Mettre à jour l'état de rafraîchissement pour montrer le chargement

    try {
      const documentList = await getDocs(  client ,  idToken);
      const formattedDocumentList = documentList.map((doc) => ({
        ...doc,
        LastUpdate: formatDate(doc.LastUpdate),
      }));

      const uniqueStatuses = [
        ...new Set(formattedDocumentList.map((doc) => doc.Status)),
      ];
      setDocuments(formattedDocumentList);
      setUniqueStatuses(uniqueStatuses);
      setRefreshing(false); // Mettre à jour l'état de rafraîchissement une fois les données mises à jour
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des données de l'API",
        error
      );
      setRefreshing(false); // Mettre à jour l'état de rafraîchissement en cas d'erreur
    }
  };

  const applyFilters = useCallback(
    (data) => {
      const filteredData = data.filter((document) => {
        const dateMatch =
          !selectedDate || document.LastUpdate.includes(selectedDate);
        const statusMatch =
          selectedStatus === "All" || document.Status === selectedStatus;

        const nameA =
          document.FileName && typeof document.FileName === "string"
            ? document.FileName.toLowerCase()
            : "";
        const nameB =
          filterValue && typeof filterValue === "string"
            ? filterValue.toLowerCase()
            : "";
        const idA =
          document.DocId && typeof document.DocId === "string"
            ? document.DocId.toLowerCase()
            : "";
        const idB =
          filterValue && typeof filterValue === "string"
            ? filterValue.toLowerCase()
            : "";

        let nameFilterMatch = false;
        let idFilterMatch = false;

        if (sortByByName) {
          nameFilterMatch = nameA.includes(nameB);
        } else if (sortByById) {
          idFilterMatch = idA.startsWith(idB) || idA === idB;
        }

        return (
          (dateMatch || !selectedDate) &&
          (statusMatch || selectedStatus === "All") &&
          ((sortByByName && nameFilterMatch) ||
            (sortByById && idFilterMatch) ||
            (!filterValue && !sortByByName && !sortByById))
        );
      });

      return filteredData;
    },
    [selectedStatus, selectedDate, filterValue, sortByByName, sortByById]
  );

  useEffect(() => {
    const sortedData = applyFilters(documents).sort((a, b) => {
      let comparisonResult = 0;

      if (comparisonResult === 0) {
        if (sortByDateAscending) {
          const dateA = new Date(
            parseInt(a.LastUpdate.substr(6, 4)),
            parseInt(a.LastUpdate.substr(3, 2)) - 1,
            parseInt(a.LastUpdate.substr(0, 2)),
            parseInt(a.LastUpdate.substr(11, 2)), // Heure
            parseInt(a.LastUpdate.substr(14, 2)) // Minutes
          );
          const dateB = new Date(
            parseInt(b.LastUpdate.substr(6, 4)),
            parseInt(b.LastUpdate.substr(3, 2)) - 1,
            parseInt(b.LastUpdate.substr(0, 2)),
            parseInt(b.LastUpdate.substr(11, 2)), // Heure
            parseInt(b.LastUpdate.substr(14, 2)) // Minutes
          );

          if (dateA.getTime() === dateB.getTime()) {
            comparisonResult =
              dateA.getHours() - dateB.getHours() ||
              dateA.getMinutes() - dateB.getMinutes();
          } else {
            comparisonResult = dateA - dateB;
          }
        } else {
          const dateA = new Date(
            parseInt(a.LastUpdate.substr(6, 4)),
            parseInt(a.LastUpdate.substr(3, 2)) - 1,
            parseInt(a.LastUpdate.substr(0, 2)),
            parseInt(a.LastUpdate.substr(11, 2)), // Heure
            parseInt(a.LastUpdate.substr(14, 2)) // Minutes
          );
          const dateB = new Date(
            parseInt(b.LastUpdate.substr(6, 4)),
            parseInt(b.LastUpdate.substr(3, 2)) - 1,
            parseInt(b.LastUpdate.substr(0, 2)),
            parseInt(b.LastUpdate.substr(11, 2)), // Heure
            parseInt(b.LastUpdate.substr(14, 2)) // Minutes
          );

          if (dateA.getTime() === dateB.getTime()) {
            comparisonResult =
              dateB.getHours() - dateA.getHours() ||
              dateB.getMinutes() - dateA.getMinutes();
          } else {
            comparisonResult = dateB - dateA;
          }
        }
      }

      if (comparisonResult === 0 && !sortByNameAscending && !sortByById) {
        const nameA = a.InstanceName ? a.InstanceName.toLowerCase() : "";
        const nameB = b.InstanceName ? b.InstanceName.toLowerCase() : "";

        comparisonResult = nameA.localeCompare(nameB);
      }

      return comparisonResult;
    });
    setFilteredDocuments(sortedData);
    setCurrentPage(1);
  }, [
    applyFilters,
    documents,
    sortByById,
    sortByDateAscending,
    sortByNameAscending,
  ]);
  const resetFilters = () => {
    setSelectedDate('');
    setFilterValue('');
    setSortByByName(false);
    setSortByById(false);
    setSelectedStatus('All');
  };

  const getStatusClass = (status) => {
    const statusMappings = configStatus.statusDocMappings;

    if (statusMappings.hasOwnProperty(status)) {
      return statusMappings[status];
    } else {
      return "bg-info";
    }
  };

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );
  const paginate = (pageNumber) => {
    const nextPageIndex = pageNumber - 1;
    const startIndex = nextPageIndex * documentsPerPage;
    const endIndex = startIndex + documentsPerPage;
    const hasNextPageData = documents.slice(startIndex, endIndex).length > 0;

    if (hasNextPageData) {
      setCurrentPage(pageNumber);
    }
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setSelectedStatus(selectedStatus);
    const filteredData = applyFilters(documents);
    setFilteredDocuments(filteredData);
  };
  const openPopup = (document) => {
    setSelectedDocument(document);
    setPopupVisible(true);
  };

  // Fonction pour fermer la popup
  const closePopup = () => {
    setSelectedDocument(null);
    setPopupVisible(false);
  };
  function generateCSVDataInstance(documents) {
    const data = [];

    data.push(["Date", "Nom du document", "Statut"]);

    documents.forEach((document) => {
      data.push([document.LastUpdate, document.FileName, document.Status]);
    });

    return data;
  }

  const toggleSortByDate = () => {
    setSortByDateAscending(!sortByDateAscending);

    setSortByStatusAscending(true);
    // Tri des données en fonction de l'ordre actuel
    const sortedData = applyFilters(documents).sort((a, b) => {
      let comparisonResult = 0;

      if (comparisonResult === 0) {
        if (sortByDateAscending) {
          const dateA = new Date(
            parseInt(a.LastUpdate.substr(6, 4)),
            parseInt(a.LastUpdate.substr(3, 2)) - 1,
            parseInt(a.LastUpdate.substr(0, 2)),
            parseInt(a.LastUpdate.substr(11, 2)), // Heure
            parseInt(a.LastUpdate.substr(14, 2)) // Minutes
          );
          const dateB = new Date(
            parseInt(b.LastUpdate.substr(6, 4)),
            parseInt(b.LastUpdate.substr(3, 2)) - 1,
            parseInt(b.LastUpdate.substr(0, 2)),
            parseInt(b.LastUpdate.substr(11, 2)), // Heure
            parseInt(b.LastUpdate.substr(14, 2)) // Minutes
          );

          if (dateA.getTime() === dateB.getTime()) {
            comparisonResult =
              dateA.getHours() - dateB.getHours() ||
              dateA.getMinutes() - dateB.getMinutes();
          } else {
            comparisonResult = dateA - dateB;
          }
        } else {
          const dateA = new Date(
            parseInt(a.LastUpdate.substr(6, 4)),
            parseInt(a.LastUpdate.substr(3, 2)) - 1,
            parseInt(a.LastUpdate.substr(0, 2)),
            parseInt(a.LastUpdate.substr(11, 2)), // Heure
            parseInt(a.LastUpdate.substr(14, 2)) // Minutes
          );
          const dateB = new Date(
            parseInt(b.LastUpdate.substr(6, 4)),
            parseInt(b.LastUpdate.substr(3, 2)) - 1,
            parseInt(b.LastUpdate.substr(0, 2)),
            parseInt(b.LastUpdate.substr(11, 2)), // Heure
            parseInt(b.LastUpdate.substr(14, 2)) // Minutes
          );

          if (dateA.getTime() === dateB.getTime()) {
            comparisonResult =
              dateB.getHours() - dateA.getHours() ||
              dateB.getMinutes() - dateA.getMinutes();
          } else {
            comparisonResult = dateB - dateA;
          }
        }
      }

      return comparisonResult;
    });

    setFilteredDocuments(sortedData);
    setCurrentPage(1);
  };


 
  const formatDate = (dateStr) => {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    const hour = dateStr.substr(9, 2);
    const minute = dateStr.substr(11, 2);

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  return (
    <div style={{ textAlign :  'center'}}>
    <NavBar />
      <div className='row'>
        <div className='col-md-6'>
          <div className='header'>
            <h3 style={{ textAlign: "left" }}>
              {t("docs")} {instanceName}
            </h3>
            <br />
            <h6 style={{ marginTop: "-20px", justifyContent: "left" }}>
              ID : {instanceId}
            </h6>
              {//<StatsTableDocs instances = {currentDocuments}/>
              }
          </div>
        </div>
        <div className='col-md-6'>
          <div className='chart-container'>
            <div>
{           //   <StatusStats documents={documents} />
}            </div>
          </div>
        </div>
      </div>
      <div className='table-container' style={{ minHeight: "400px" }}>
       
        {isLoading ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='4'>{t("chargement")}</td>
              </tr>
            </tbody>
          </table>
        ) : !documents.length ? (
          <table>
            <tbody>
              <tr>
                <td colSpan='4'>{t("noData")}</td>
                <td style={{ width: "75px" }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={onBack}
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      marginRight: "auto",
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
           <table key='tableHeader' className='table table-hover'>
          <thead>
            <tr>
              <th style={{ width: "150px" }}>{t("dateCreationEntete")}</th>
              <th style={{ width: "150px" }}>
                {t("dateLastUpdateEntete")}
                <button
                  onClick={toggleSortByDate}
                  style={{
                    marginLeft: "5px",
                    padding: "0",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {sortByDateAscending ? (
                    <FontAwesomeIcon icon={faSortUp} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} />
                  )}
                </button>
              </th>
              <th style={{ width: "450px" }}>{t("nomDocumentEntete")}</th>
              <th style={{ width: "100px" }}>{t("statusEntete")}</th>
              <th style={{ width: "200px" }}></th>
            </tr>
          
            <tr>
              <td>
                <input
                  style={{
                    boxSizing: "border-box",
                    width: "auto",
                    height: "30px",
                    borderRadius: "3px",
                    marginLeft: "80px", // Légèrement décalé vers la gauche
                    margin: "0",
                    border: "1px solid #ffff",
                    borderColor: selectedDate ? "#0034CF" : "#161616",
                    color: "#3c6382",
                    paddingRight: selectedDate ? "30px" : "10px",
                    boxShadow: selectedDate ? "0 0 5px 0 #0034CF" : "",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                  }}
                  type='text'
                  placeholder={t("JJMMAAAA")}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </td>
              <td></td>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "-20px",
                }}
              >
                {" "}
                {/* Légèrement décalé vers la gauche */}
                <input
                  style={{
                    width: "calc(100% - 100px)", // Ajustement de la largeur
                    height: "30px",
                    borderRadius: "3px",
                    margin: "0",
                    border: "1px solid #ffff",
                    borderColor: filterValue ? "#0034CF" : "#161616",
                    color: "#3c6382",
                    paddingRight: filterValue ? "30px" : "10px",
                    boxShadow: filterValue ? "0 0 5px 0 #0034CF" : "",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                  }}
                  type='text'
                  placeholder={t("filtreDoc-Recherche")}
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
                <select
                  style={{
                    width: "auto",
                    height: "30px",
                    borderRadius: "3px",
                    borderColor: "#3c6382",
                    color: "#3c6382",
                    marginLeft: "-5px",
                  }}
                  value={sortByByName ? "name" : sortByById ? "id" : ""}
                  onChange={(e) => {
                    if (e.target.value === "name") {
                      setSortByByName(true);
                      setSortByById(false);
                    } else if (e.target.value === "id") {
                      setSortByByName(false);
                      setSortByById(true);
                    } else {
                      setSortByByName(false);
                      setSortByById(false);
                    }
                  }}
                >
                  <option value='name'>{t("filtreInstance-Nom")}</option>
                  <option value='id'>{t("filtreInstance-Id")}</option>
                </select>
              </td>
              <td style={{ paddingLeft: "5px" }}>
                {" "}
                {/* Légèrement décalé vers la gauche */}
                <select
                  style={{
                    width: "100%",
                    height: "30px",
                    borderRadius: "3px",
                    borderColor: "#3c6382",
                    color: "#3c6382",
                  }}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value='All'>{t("toutStatus")}</option>
                  {uniqueStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={resetFilters}
                  style={{ marginLeft: "auto", marginRight: "auto"}}
                >
                  Delete Filters
                </button>
              </td>
            </tr>
          </thead>
          {currentDocuments.map((document) => (
        <tbody key={document.DocId}>
          <tr>
            <td style={{ width: "150px" }}>{formatDate(document.CreationDate)}</td>
            <td style={{ width: "150px" }}>{document.LastUpdate}</td>
            <td style={{ width: "450px", padding: "4px" }}>
              <p style={{ fontSize: "16px", margin: 0 }}>{document.FileName}</p>
              <p style={{ fontSize: "10px", lineHeight: "1", margin: 0 }}>{document.DocId}</p>
            </td>
            <td className={getStatusClass(document.Status)} style={{ width: "100px", color: "#fff" }}>
              {document.Status}
            </td>
           
            <td>
            <button onClick={() => openPopup(document)}>Relancer</button>

            </td>
          </tr>
        </tbody>
      ))}
          </table>
          </>
        )}
        <div className='pagination'>
          <button
            style={{ marginRight: " 10px" }}
            onClick={
              () => navigate("/Admin/DashBoard")
            }
            className='btn btn-danger'
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                marginRight: "auto",
              }}
            />{" "}
            {t("btnRetour")}{" "}
          </button>
          {shouldEnableDWLDCSV && (
            <button className='btn btn-warning' style={{ marginRight: "10px" }}>
              <CSVLink
                data={generateCSVDataInstance(documents)}
                filename={`${instanceName}.csv`}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    marginRight: "auto",
                  }}
                />
                {"  "}
                {t("btnDWLDCSV")}
              </CSVLink>
            </button>
          )}
          {currentPage !== 1 && (
            <button
              style={{ marginRight: "10px", marginLeft: "auto" }}
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  marginRight: "auto",
                }}
              />
              {"  "}
              {t("btnPrecedent")}
            </button>
          )}
          {"   "}
          {documents.slice(
            currentPage * documentsPerPage,
            (currentPage + 1) * documentsPerPage
          ).length > 0 && (
            <button
              className='custom-button'
              onClick={() => paginate(currentPage + 1)}
              style={{ color: "#fff", marginLeft: "auto" }}
            >
              {t("btnSuivant")} {"  "}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  marginRight: "auto",
                }}
              />
            </button>
          )}
          <button
            style={{
              cursor: "pointer",
              fontSize: "16px",
              marginRight: "auto",
            }}
            onClick={handleRefresh}
            disabled={refreshing} // Désactiver le bouton lors du rafraîchissement en cours
          >
            Rafraîchir
          </button>
          {popupVisible && <DocumentPopup document={selectedDocument} onClose={closePopup} />}

        </div>
      </div>
    </div>
  );
};

export default DocsTableau;
