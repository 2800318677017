import React, { useEffect, useState } from "react";
import fetchModel from "../../../../API/ModelADM";
import fetchClient from "../../../../API/ClientList";
import { useNavigate } from "react-router-dom";

const DocSection = ({ token }) => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [instanceId, setInstanceId] = useState(""); // Changed to an empty string for InstanceId
  const navigate = useNavigate();

  const fetchModelsForClient = async (modelId) => {
    try {
      const clientsData = await fetchClient(token, modelId);
      setClients(clientsData);
      if (clientsData.length > 0) {
        // Optionally set a default client here
      }
    } catch (error) {
      console.error("Error fetching clients for model:", error);
    }
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await fetchModel(null, token);
        setModels(modelsData);
        if (modelsData.length > 0) {
          const defaultModel = modelsData[0];
          setSelectedModel(defaultModel.ModelId);
          fetchModelsForClient(defaultModel.ModelId);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [token]);

  const handleRedirect = () => {
    if (selectedModel && selectedClient && instanceId.trim() !== "") {
      localStorage.setItem('selectedClient', selectedClient);
      localStorage.setItem('selectedModel', selectedModel);
      localStorage.setItem('Status', instanceId);
      navigate('/Admin/DocDashboard');
    } else {
      console.warn('Veuillez sélectionner un client, un modèle et entrer un InstanceId avant de rediriger.');
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="selectModel">Select a model :</label>
        <select
          className="form-control"
          id="selectModel"
          onChange={(e) => {
            const selectedValue = e.target.value === "null" ? undefined : e.target.value;
            setSelectedModel(selectedValue);
            fetchModelsForClient(selectedValue);
          }}
        >
          <option value="">Choose a model</option>
          {models.map((model) => (
            <option key={model.ModelId} value={model.ModelId}>
              {model.ModelName}
            </option>
          ))}
        </select>
      </div>

      {selectedModel && (
        <div>
          <div className="form-group">
            <label htmlFor="selectSecondInput">Second input dependent on the model:</label>
            <select
              className="form-control"
              id="selectSecondInput"
              onChange={(e) => {
                const selectedValue = e.target.value === "null" ? undefined : e.target.value;
                setSelectedClient(selectedValue);
                setInstanceId(""); // Reset InstanceId when client changes
              }}
            >
              <option value="">Choose a client</option>
              {clients.map((client) => (
                <option key={client.ClientId} value={client.ClientId}>
                  {client.ClientId === "null" ? undefined : client.ClientId}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="instanceId">Status:</label>
            <input
              type="text"
              className="form-control"
              id="instanceId"
              value={instanceId}
              onChange={(e) => setInstanceId(e.target.value)}
              placeholder="Enter Instance Id"
            />
          </div>
        </div>
      )}
      <button type="button" className="btn btn-primary" onClick={handleRedirect}>
        Rediriger
      </button>
    </div>
  );
};

export default DocSection;
