import {Routes, Route} from 'react-router-dom'
import Login from '../Component/Login/Login'
import LoginADM from '../Component/Login/LoginADM'
export default function RouterDefault() {
  return (
    <Routes>
      
      <Route path="/*" element={<Login />} />
      <Route path = '/LoginADM' element={<LoginADM/>} />
    </Routes>
    )
}
