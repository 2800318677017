/** @format */

import apiConfig from "../config/_config";

export async function getPDFview(DocId, InstanceId, modelId,token) {
  const apiUrl = `${apiConfig}/document/view`;
  const requestBody = {
    DocumentId: DocId,
    InstanceId: InstanceId,
    ModelId: modelId,
    Duration: 600,
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: token
  };

  const requestOptions = {
    method: "POST",
    headers,
    body: JSON.stringify(requestBody)
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    const responseData = await response.json();
    if (response.ok) {
      return responseData;
    } else {
      throw new Error(`Erreur de l'API: ${responseData.message}`);
    }
  } catch (error) {
    throw new Error(`Erreur lors de la requête API: ${error.message}`);
  }
}
