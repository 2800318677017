import process from 'process';

let apiConfig;

const environment = process.env.REACT_APP_ENV;
const devAPI = process.env.REACT_APP_API_DEV;
if(environment === 'dev') {
    apiConfig =devAPI 
}
else if(environment === 'lumdev') {
    apiConfig =  'https://api-dev.myactivhub.net'
    }
else if(environment === 'prod') {
    apiConfig =  'https://api-dev.myactivhub.net'
    }
else if(environment === 'staging') {
    apiConfig =  'https://api-dev.myactivhub.net'
    }
else {
    apiConfig = 'https://api-dev.myactivhub.net'
    }

export default apiConfig;