/** @format */

import  { useState, useEffect } from "react";
import { getModelList } from "../../API/ModelList";
import SwitchTableau from "./Tableau/SwitchTableau";
import "./index.css";
import Cookies from "js-cookie";
export default function DashBoard() {

  const [modelList, setModelList] = useState([]);

  const idToken = Cookies.get('Token');
  useEffect(() => {

    if (idToken) {
      getModelList(idToken)
        .then((data) => {
          setModelList(data.message.list);
        })
        .catch((error) => {
          console.error("Error fetching model list:", error);
        });
    } else {
    }
  }, [idToken ]);

  return (
    <>
      <div className='container' style={{ backgroundColor: "#f2f0f0" }}>
        <div className='footer'>
          <SwitchTableau modelList={modelList} />
        </div>
      </div>
    </>
  );
}
