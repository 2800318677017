import React, { useState } from 'react';
import Cookies from 'js-cookie';
import  docRelance from '../../../API/docRelance'; // Assurez-vous de fournir le chemin correct

const InstancePopup = ({ instance, onClose }) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [newStatus, setNewStatus] = useState('');
  const [oldStatus, setOldStatus] = useState(''); // Ajout du champ pour l'ancien statut
  const [instanceId, setInstanceId] = useState(instance.InstanceId); // Ajout du champ pour l'instance ID
  const idToken = Cookies.get("Token");
console.log(instance)
  const handleCurrentStatusChange = (e) => {
    setCurrentStatus(e.target.value);
  };

  const handleNewStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleOldStatusChange = (e) => {
    setOldStatus(e.target.value);
  };

  const handleInstanceIdChange = (e) => {
    setInstanceId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token =  idToken;
      const docList = await docRelance(oldStatus, currentStatus, newStatus, instanceId, token); // Passer les bons arguments à la fonction docRelance
      console.log('Liste de documents mise à jour:', docList);

      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour des documents:', error);
      console.log(error);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={stopPropagation}>
        <div className="popup-content">
          <h2>Modifier le statut du document</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Ancien statut:
              <input type="text" value={oldStatus} onChange={handleOldStatusChange} />
            </label>
            <label>
              Statut actuel:
              <input type="text" value={currentStatus} onChange={handleCurrentStatusChange} />
            </label>
            <label>
              Nouveau statut:
              <input type="text" value={newStatus} onChange={handleNewStatusChange} />
            </label>
            <label>
              Instance ID:
              <input type="text" value={instanceId} onChange={handleInstanceIdChange} />
            </label>
            <button type="submit">Enregistrer</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InstancePopup;
