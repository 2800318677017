import  { useEffect, useState } from 'react';
import { fetchDocuments } from '../../../API/Docs';
import  StatusStats  from './StatsStatusDocs';

export default function StatsDocs({ instanceId }) {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const documentList = await fetchDocuments(instanceId);
        setDocuments(documentList);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
      }
    }

    fetchData();
  }, [instanceId]);

  return (
    <div>
      <StatusStats documents={documents} />
    </div>
  );
}

