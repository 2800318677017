import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Vérifiez si la langue est stockée dans le stockage local
const storedLanguage = localStorage.getItem('language');

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json'),
      },
      fr: {
        translation: require('./locales/fr.json'),
      },
    },
    lng: storedLanguage || 'en', // Utilisez la langue stockée ou la langue par défaut
    fallbackLng: 'en', // Langue de secours en cas de problème
    interpolation: {
      escapeValue: false,
    },
  });

// Fonction pour mettre à jour la langue stockée
function setLanguage(lang) {
  i18n.changeLanguage(lang);
  localStorage.setItem('language', lang);
}
export function getLanguage() {
  return localStorage.getItem("language");
}

export { i18n, setLanguage};
